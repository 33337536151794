import React from 'react';
import cn from 'classnames';
import arrow from '../../../images/icons/left-arrow.svg';
import blackArrow from '../../../images/icons/black-left-arrow.svg';
import styles from './PrevArrow.module.scss';

interface PrevArrowArgs {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  externalClassName?: string;
  black?: boolean;
}

export function PrevArrow({ className, style, onClick, externalClassName, black }: PrevArrowArgs) {
  return (
    <div
      className={cn({
        [styles.prev]: true,
        [styles.black]: black,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={black ? blackArrow : arrow} alt="arrow" />
    </div>
  );
}
