import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './MiniProductBlock.module.scss';

function MiniProductBlock({ photo, title, subtitle }) {
  return (
    <div className={styles.miniProduct}>
      <GatsbyImage
        image={photo.localFile.childImageSharp.gatsbyImageData}
        alt="фото продукта"
        className={styles.image}
      />
      <div className={styles.description}>
        <span className={styles.p1}>{title}</span>
        <span className={styles.p2}>{subtitle}</span>
      </div>
    </div>
  );
}

export default MiniProductBlock;
