import React from 'react';
import type {
  OnCompleteCallbackType,
  PhotoUploaderOptions,
  PhotoUploaderUiOptions,
} from '@customTypes/photoUploader';

import { PhotoUploader } from '../PhotoUploader';

import '../styles.scss';

interface FilePickerArgs {
  options?: PhotoUploaderOptions;
  uiOptions?: PhotoUploaderUiOptions;
}

export const useFilePicker = ({ options, uiOptions }: FilePickerArgs) =>
  React.useCallback(
    (onComplete?: OnCompleteCallbackType, maxAllowedFiles?: number, onDrop?: () => void): void => {
      const uploader = new PhotoUploader(options);
      const { openModal, closeModal } = uploader.useDashboard({
        ...uiOptions,
      });

      uploader.setOptions({
        ...(maxAllowedFiles ? { restrictions: { maxNumberOfFiles: maxAllowedFiles } } : {}),
      });

      uploader.onComplete = (files) => {
        if (onComplete) {
          uploader.onComplete = onComplete;
        }
        uploader.onComplete(files);
        if (closeModal) {
          closeModal();
        }
      };

      if (openModal) openModal();
    },
    [options, uiOptions]
  );
