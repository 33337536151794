import React from 'react';

import { SVG } from '@svgdotjs/svg.js';
import styles from './VerticalCertificate.module.scss';

const today = new Date();
const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
const dd = String(today.getDate()).padStart(2, '0');
const mm = months[today.getMonth()];
const yyyy = today.getFullYear() + 1;

interface VerticalCertificateArgs {
  image: string;
  text: string;
  nameTo?: string;
  nameFrom?: string;
  sum?: number;
}

function VerticalCertificate({ image, text, nameTo, nameFrom, sum }: VerticalCertificateArgs) {
  const svg = SVG(image);
  const certText = svg.findOne('#text');
  const nameFromText = svg.findOne('#name_from');
  const nameToText = svg.findOne('#name_to');
  const priceText = svg.findOne('#sum');
  const dateText = svg.findOne('#date_to');
  const normalizedText = text.replaceAll('\n', '<br/>');

  if (certText) certText.node.innerHTML = normalizedText;
  if (nameFromText) nameFromText.node.innerHTML = nameFrom || '';
  if (nameToText) nameToText.node.innerHTML = `${nameTo}!`;
  if (priceText) priceText.node.innerHTML = `${sum} ₽`;
  if (dateText) dateText.node.innerHTML = `до ${dd} ${mm} ${yyyy}`;

  return <div className={styles.svgContainer} dangerouslySetInnerHTML={{ __html: svg.svg() }} />;
}

export default VerticalCertificate;
