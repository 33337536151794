import type { UploadResult, UppyOptions } from '@uppy/core';
import Uppy from '@uppy/core';
import type { DashboardOptions } from '@uppy/dashboard';
import Dashboard from '@uppy/dashboard';
import type { ImageEditorOptions } from '@uppy/image-editor';
import ImageEditor from '@uppy/image-editor';
import Russian from '@uppy/locales/lib/ru_RU';
import AwsS3 from '@uppy/aws-s3';
import type {
  OnCompleteCallbackType,
  PhotoUploaderArgs,
  PhotoUploaderOptions,
  UploadedFileArgs,
} from '@customTypes/photoUploader';
import { getRandomString } from '@utils/getRandomString';

const companionUrl = process.env.COMPANION_URL || 'https://upload.periodica.press/';
const isDevelopment = process.env.GATSBY_ACTIVE_ENV === 'development';

const renameUploadDefault = (extension: string) => {
  const today = new Date();
  return `uploads${isDevelopment ? '-dev' : ''}/${today.getFullYear()}-${
    today.getMonth() + 1
  }/${getRandomString(36)}${extension ? `.${extension}` : ''}`;
};
const onCompleteDefault = (files: UploadedFileArgs[]) => files;

const defaultOptions = {
  autoProceed: false,
  allowMultipleUploads: false,
  debug: false,
  locale: Russian,
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    maxFileSize: 26214400,
    allowedFileTypes: ['.jpg', '.jpeg'],
  },
  onBeforeFileAdded: (currentFile) => {
    const newName = renameUploadDefault(currentFile.extension);
    return {
      ...currentFile,
      meta: {
        ...currentFile.meta,
        name: newName,
      },
      name: newName,
    };
  },
} as UppyOptions;

const editorActions = {
  revert: true,
  rotate: true,
  granularRotate: true,
  flip: true,
  zoomIn: true,
  zoomOut: true,
  cropSquare: true,
  cropWidescreen: true,
  cropWidescreenVertical: false,
};

const editorOptions = {
  target: Dashboard,
  quality: 1,
  cropperOptions: {
    croppedCanvasOptions: {
      minWidth: 836,
      minHeight: 594,
    },
    aspectRatio: 1.4,
  },
  actions: editorActions,
  locale: Russian,
} as ImageEditorOptions;

const defaultS3Options = {
  limit: 5,
  companionUrl,
};

export class PhotoUploader implements PhotoUploaderArgs {
  private uppy: Uppy;

  onComplete: OnCompleteCallbackType;

  constructor(options?: any) {
    const opts = {
      id: `uploader_${getRandomString()}`,
      ...defaultOptions,
      ...options,
      restrictions: {
        ...defaultOptions.restrictions,
        ...(options && options.restrictions),
      },
    } as PhotoUploaderOptions;

    this.onComplete = opts.onComplete || onCompleteDefault;

    const onComplete = (result: UploadResult) => {
      const successFiles = result.successful.map((file) => ({
        source: 'local',
        url: file.uploadURL,
      }));
      this.onComplete(successFiles);
    };

    this.uppy = new Uppy(opts);

    this.uppy.use(AwsS3, {
      ...defaultS3Options,
      ...(options && options.s3Options),
    });

    this.uppy.on('complete', onComplete);

    this.uppy.on('error', (error) => {
      // NOTE: we need to disable no-console for error logging
      // eslint-disable-next-line no-console
      console.error(`Something went wrong with file upload: ${error.cause}`);
      // eslint-disable-next-line no-console
      console.error(`Stack trace: ${error.stack}`);
    });
  }

  addFile(file: File, source: string): string {
    try {
      return this.uppy.addFile({
        data: file,
        name: file.name,
        type: file.type,
        source,
        isRemote: false,
      });
    } catch (err) {
      return '';
    }
  }

  close() {
    this.uppy.close();
  }

  useDashboard(options?: DashboardOptions) {
    this.uppy
      .use(Dashboard, {
        ...options,
        onRequestCloseModal: () => {
          if (options && options?.onRequestCloseModal) {
            options.onRequestCloseModal();
          }
          this.close();
        },
      })
      .use(ImageEditor, { ...editorOptions });
    const dashboardPlugin: Dashboard | undefined = this.uppy.getPlugin('Dashboard');
    return { openModal: dashboardPlugin?.openModal, closeModal: dashboardPlugin?.closeModal };
  }

  upload(onComplete?: OnCompleteCallbackType) {
    if (onComplete) {
      this.onComplete = onComplete;
    }

    this.uppy.upload();
  }

  setOptions(options: PhotoUploaderOptions) {
    this.uppy.setOptions(options);
  }
}
