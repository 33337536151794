import React from 'react';

import { useFilePicker } from '@services/PhotoUploader';
import type { UploadedFileArgs } from '@customTypes/photoUploader';
import { TooltipButton } from '@components/Blocks/Tooltip';
import PlusIcon from '@images/icons/gift/plus-icon.svg';
import RefreshIconWhite from '@images/icons/gift/refresh-icon-white.svg';
import CloseIconWhite from '@images/icons/close-white.svg';

import styles from './CertificateImageUpload.module.scss';

const UPLOAD_SUBTITLE = '836 x 594, .JPG/.JPEG до 25 МБ';
const RESIZE_URL =
  'https://imgproxy.periodica.press/insecure/ar:1/q:95/crop:0:0:sm/g:sm/rs:fill-down:836:594/plain/';

interface CertificateImageUploadArgs {
  onUploadPhotos: (photo: string) => void;
  onDelete: () => void;
  uploadedFile: string | null;
  error: string | undefined;
}

export function CertificateImageUpload({
  onUploadPhotos,
  uploadedFile,
  onDelete,
  error,
}: CertificateImageUploadArgs) {
  const openPicker = useFilePicker({
    uiOptions: {
      note: UPLOAD_SUBTITLE,
      showRemoveButtonAfterComplete: true,
      autoOpenFileEditor: true,
    },
  });

  const handleUploadPhoto = React.useCallback(() => {
    openPicker((photos: UploadedFileArgs[]) => {
      if (photos.length) {
        const { url } = photos[0];
        const finalUrl = `${RESIZE_URL}${url}`;
        onUploadPhotos(finalUrl);
      }
    });
  }, [openPicker, onUploadPhotos]);

  return (
    <>
      {!uploadedFile && (
        <div
          role="presentation"
          className={styles.imageUploadContainer}
          onClick={handleUploadPhoto}
        >
          <img className={styles.icon} src={PlusIcon} alt="Добавить фото" />
          <p className={styles.title}>Нажмите, чтобы загрузить</p>
          <p className={styles.subtitle}>{UPLOAD_SUBTITLE}</p>
        </div>
      )}
      {uploadedFile && (
        <div className={styles.customPhotoPreviewContainer}>
          <img className={styles.customPhotoPreview} src={uploadedFile} alt="Загруженное фото" />
          <div className={styles.customPhotoPreviewControls}>
            <div className={styles.customPhotoPreviewControlsInner}>
              <TooltipButton icon={RefreshIconWhite} onClick={handleUploadPhoto} text="Заменить" />
              <TooltipButton icon={CloseIconWhite} onClick={onDelete} text="Удалить" />
            </div>
          </div>
        </div>
      )}
      {error && <div className={styles.errorLabel}>{error}</div>}
    </>
  );
}
