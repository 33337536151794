import React from 'react';

import type { PhotoUploaderOptions, UploadedFileArgs } from '@customTypes/photoUploader';
import { PhotoUploader } from '../PhotoUploader';

export const useSingleFileDrop = (onUpload: any, options?: PhotoUploaderOptions) => {
  const handleUpload = React.useCallback(
    (files: UploadedFileArgs[]) => {
      const file = files.length > 0 ? files[0] : null;
      onUpload(file);
    },
    [onUpload]
  );

  return React.useCallback(
    (files: File[]) => {
      const uploader = new PhotoUploader(options);
      uploader.addFile(files[0], 'drop');
      uploader.upload((successFiles) => {
        handleUpload(successFiles);
        uploader.close();
      });
    },
    [handleUpload, options]
  );
};
