import React from 'react';
import cn from 'classnames';

import type { AccordionItemArgs } from '@customTypes/accordion';

import downChevron from '../../../../../images/icons/down-chevron.svg';

import styles from './AccordionItem.module.scss';

export function AccordionItem({ question, answer }: AccordionItemArgs) {
  const [isItemOpen, setIsItemOpen] = React.useState(false);

  const handleOpenItem = () => {
    setIsItemOpen(!isItemOpen);
  };

  return (
    <div className={styles.accordionItemContainer}>
      <div className={styles.questionContainer} onClick={handleOpenItem}>
        <p className={styles.question}>{question}</p>
        <img
          className={cn({ [styles.openIcon]: true, [styles.open]: isItemOpen })}
          src={downChevron}
          alt="Посмотреть ответ"
        />
      </div>

      <div className={cn({ [styles.answerContainer]: true, [styles.open]: isItemOpen })}>
        <div className={styles.answer} dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </div>
  );
}
