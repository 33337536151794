import React from 'react';

import VerticalCertificate from './VerticalCertificate';

interface CertificateArgs {
  image: string;
  text: string;
  nameTo?: string;
  nameFrom?: string;
  sum?: number;
}

function Certificate({ image, text, nameTo = '', nameFrom = '', sum = 0 }: CertificateArgs) {
  return (
    <VerticalCertificate image={image} text={text} nameTo={nameTo} nameFrom={nameFrom} sum={sum} />
  );
}

export default Certificate;
