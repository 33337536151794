export const GIFT_OPTION_DEFAULT = '0';
export const GIFT_OPTION_CUSTOM = '1';

export const GIFT_SUM_DEFAULT = '0';
export const GIFT_SUM_CUSTOM = '1';

export const DEFAULT_PRICE = 2990;
export const TYPE_RECIPIENT_EMAIL = 1;

export const CUSTOM_CERT_ID_DEV = 770;
export const CUSTOM_CERT_ID_PROD = 768;
