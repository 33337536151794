import React from 'react';

import type { AccordionArgs } from '@customTypes/accordion';
import { AccordionHeader } from './components/AccordionHeader';
import { AccordionItem } from './components/AccordionItem';

import styles from './Accordion.module.scss';

export function Accordion({ title, items }: AccordionArgs) {
  return (
    <div className={styles.accordionContainer}>
      <AccordionHeader title={title} />

      {items.map((item) => (
        <div className={styles.accordionItem} key={`accordionGroup_${item.groupTitle}`}>
          <AccordionHeader subtitle={item.groupTitle} />
          {item.groupItems.map((groupItem) => (
            <AccordionItem
              key={`accordionItem_${groupItem.title}`}
              question={groupItem.title}
              answer={groupItem.answer}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
