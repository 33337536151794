import React from 'react';
import cn from 'classnames';
import arrow from '../../../images/icons/right-arrow.svg';
import blackArrow from '../../../images/icons/black-right-arrow.svg';
import styles from './NextArrow.module.scss';

interface NextArrowArgs {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  externalClassName?: string;
  black?: boolean;
}
export function NextArrow({ className, style, onClick, externalClassName, black }: NextArrowArgs) {
  return (
    <div
      className={cn({
        [styles.next]: true,
        [styles.black]: black,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={black ? blackArrow : arrow} alt="arrow" />
    </div>
  );
}
