import React from 'react';
import styles from './DeliveryInformation.module.scss';

interface DeliveryInformationArgs {
  mail: string;
  deliveryToPerson?: boolean;
  deliveryDate?: string;
  phone: string;
}

function DeliveryInformation({
  mail,
  deliveryToPerson,
  deliveryDate,
  phone,
}: DeliveryInformationArgs) {
  return (
    <div>
      <h3 className={styles.h3}>Информация для доставки</h3>

      <span className={styles.s3}>
        {deliveryToPerson ? 'E-mail получателя' : 'E-mail отправителя'}
      </span>
      <p className={styles.p1}>{mail}</p>
      {deliveryDate && (
        <>
          <span className={styles.s3}>Дата отправки</span>
          <p className={styles.p1}>{deliveryDate}</p>
        </>
      )}
      <span className={styles.s3}>Телефон отправителя</span>
      <p className={styles.p1}>{phone}</p>
    </div>
  );
}

export default DeliveryInformation;
