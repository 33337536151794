import axios from 'axios';

const baseUrl = process.env.GATSBY_API_URL || 'https://test-api1.periodica.press/api';

const headers = {
  'Content-Type': 'application/json',
  'Accept-Version': 'v4',
};

const EXCLUDED_CERT_TYPES = ['CERT_CUSTOM', 'CERT_COLLECT_BOOK'];

export const getGiftCards = async () => {
  const result = await axios({
    method: 'GET',
    url: `${baseUrl}/templates/gift_card`,
    headers,
  });
  const cards = result.data.filter((card) => EXCLUDED_CERT_TYPES.indexOf(card.name) === -1);
  return cards.map((card, idx) => ({
    template_id: card.id,
    slideId: idx,
    image: card.preview_url,
    backImage: card.back_page_layout,
    frontLayout: card.preview,
    isTextEnabled: card.has_text,
  }));
};

export const postSmsOnPhone = (phone) =>
  axios({
    method: 'POST',
    url: `${baseUrl}/sms/code`,
    data: { phone },
    headers,
  });

export const validateSmsCode = async (phone, code) => {
  const result = await axios({
    method: 'POST',
    url: `${baseUrl}/sms/validate`,
    data: { phone, code },
    headers,
  });
  return result.status === 200;
};

/* eslint-disable camelcase */
export const orderGift = async (
  template_id,
  name,
  phone,
  email,
  to_name,
  to_email,
  message,
  price,
  previewUrl,
  client_newsletter_agreed
) => {
  const normalizedMessage = message.replaceAll('\n', '<br/>');
  const data = {
    template_id,
    client_newsletter_agreed,
    gift_card_info: {
      name,
      phone,
      email,
      to_name,
      to_email: to_email || email,
      message: normalizedMessage || ' ',
    },
    price,
    ...(previewUrl && {
      preview_url: previewUrl,
    }),
  };
  const result = await axios({
    method: 'POST',
    url: `${baseUrl}/gift/order`,
    data,
    headers,
  });
  return result.data;
};
/* eslint-enable camelcase */
