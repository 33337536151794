import React from 'react';
import cn from 'classnames';
import styles from './Switcher.module.scss';

type OptionArgs = {
  id: string;
  title: string;
};

interface SwitcherArgs {
  activeOption: string;
  onSwitch: (option: string) => void;
  options: OptionArgs[];
  disabled?: boolean;
  customClassName?: string;
}

export function Switcher({
  activeOption,
  onSwitch,
  options,
  disabled = false,
  customClassName = '',
}: SwitcherArgs) {
  const handleSwitch = (option: string) => {
    if (disabled) {
      return;
    }

    onSwitch(option);
  };
  return (
    <div className={cn({ [styles.switcher]: true, [customClassName]: !!true })}>
      {options.map((option) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          className={cn({
            [styles.option]: true,
            [styles.optionSelected]: activeOption === option.id,
            [styles.disabled]: disabled,
          })}
          onClick={() => handleSwitch(option.id)}
          key={`switcher-btn-${option.id}`}
          type="button"
        >
          {option.title}
        </button>
      ))}
    </div>
  );
}
