import propTypes from 'prop-types';

const countables = {
  pages: ['страница', 'страницы', 'страниц'],
  items: ['штука', 'штуки', 'штук'],
  days: ['день', 'дня', 'дней'],
  shots: ['снимок', 'снимка', 'снимков'],
};

export function findCountable({ value, type }) {
  const words = countables[type];
  let result = `${value} ${words[2]}`;

  if (value % 10 === 1 && value % 100 !== 11) {
    result = `${value} ${words[0]}`; // one
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
    result = `${value} ${words[1]}`; // few
  } else {
    result = `${value} ${words[2]}`; // many
  }

  return result;
}

findCountable.propTypes = {
  value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  type: propTypes.string.isRequired,
};

findCountable.defaultProps = {
  type: 'pages',
};
