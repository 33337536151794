export const generateCustomGiftSvg = (
  url: string
) => `<svg viewBox="0 0 418 591" width="849" height="1200" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewport-fill="white">
    <defs>
        <style>
            @import url('https://fonts.googleapis.com/css?family=Open+Sans&amp;display=swap&amp;subset=cyrillic,cyrillic-ext,latin-ext');
            @import url('https://static.periodica.press/public/fonts/periodica-fonts.css');

            .gift__text {
                display: none;
                font-family: 'Aeonik Pro';
                font-size: 23px;
                line-height: 120%;
                color: #FFFFFF;
                text-align: center;
                display: -ms-flexbox;
                display: flex;
                display: -webkit-box;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-pack: center;
                height: 100%;
            }
        </style>
    </defs>
    <image id="bg" x="0" y="0" width="418" height="297"
        xlink:href=${url}
        preserveAspectRatio="xMidYMid slice" />
    <g>
        <foreignObject x="97" y="99" width="223" height="94">
            <div id="text" xmlns="http://www.w3.org/1999/xhtml" class="gift__text" style="opacity: 0">
                test
            </div>
        </foreignObject>
    </g>
    <g>


        <text id="name_to" x="49" y="349" font-family="Aeonik Pro" fill="#2747AE" font-size="30"
            text-anchor="start">Мария!</text>

        <text x="50" y="388" font-family="Aeonik Pro" fill="#2747AE" font-size="12" text-anchor="start">Вам дарит
            сертификат</text>
        <text id="name_from" x="50" y="412" font-family="Aeonik Pro" fill="#2747AE" font-size="20"
            text-anchor="start">Елена Игоревна</text>

        <text x="278" y="388" font-family="Aeonik Pro" fill="#2747AE" font-size="12" text-anchor="start">Номинал</text>
        <text id="sum" x="278" y="412" font-family="Aeonik Pro" fill="#2747AE" font-size="20" text-anchor="start">1290
            ₽</text>

        <text x="50" y="445" font-family="Aeonik Pro" fill="#2747AE" font-size="12" text-anchor="start">Действие
            сертификата</text>
        <text id="date_to" x="50" y="468" font-family="Aeonik Pro" fill="#2747AE" font-size="20" text-anchor="start">до
            15 июля 2023</text>

    </g>
    <g>
        <rect x="50" y="502" width="318" height="51" fill="#2747AE" rx="10" />
        <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Aeonik Pro" font-size="12"
            letter-spacing="0em" x="65" y="533">Промокод
        </text>
        <text id="promocode" fill="white" xml:space="preserve" style="white-space: pre" font-family="Aeonik Pro"
            font-size="20" x="356" y="536" text-anchor="end">XYZ-XYZ</text>
        <text fill="#2747AE" xml:space="preserve" style="white-space: pre" font-family="Parmigiano Text Pro Thin"
            font-size="10" letter-spacing="0em" x="50" y="567"> Промокод можно применить несколько раз до исчерпания баланса.  
        </text>
<text fill="#2747AE" xml:space="preserve" style="white-space: pre" font-family="Parmigiano Text Pro Thin"
            font-size="10" letter-spacing="0em" x="50" y="580"> Не суммируется с другими промокодами
        </text>
    </g>

    <g id="logo" transform="translate(278 437)">
        <path
            d="M26.4167 3.87383C24.7364 3.87383 23.5314 4.72491 23.0843 5.41139V5.43038L22.8899 3.9896H21.575V16.5316H23.0777V11.5734C23.5248 12.2563 24.7299 13.0983 26.4101 13.0983C29.0626 13.0983 30.9955 11.2451 30.9955 8.48561C31.0021 5.76322 29.118 3.87383 26.4167 3.87383ZM26.2617 11.7597C24.4772 11.7597 23.0843 10.7404 23.0843 8.7479V8.23326C23.0843 6.24348 24.4734 5.2115 26.2617 5.2115C28.2041 5.2115 29.4768 6.45512 29.4768 8.48561C29.4768 10.5161 28.2041 11.7597 26.2617 11.7597V11.7597Z"
            fill="#2747AE" />
        <path
            d="M15.5344 3.87383C12.8106 3.87383 10.8711 5.8003 10.8711 8.48651C10.8711 11.1094 12.6491 13.0992 15.4179 13.0992C17.9436 13.0992 19.4886 11.7796 19.8577 9.97433H18.3549C18.0441 11.1438 16.9555 11.8076 15.479 11.8076C13.5846 11.8076 12.4575 10.4329 12.4575 8.95411V8.87904H19.9864C20.0061 8.66469 20.0258 8.45847 20.0258 8.26221C20.023 5.83829 18.2836 3.87383 15.5344 3.87383ZM12.542 7.67251C12.6876 6.21363 14.0382 5.14638 15.4959 5.14638C17.04 5.14638 18.2648 6.11957 18.4075 7.67251H12.542Z"
            fill="#2747AE" />
        <path d="M0.932861 12.9952H2.4394V5.3146H7.81184V12.9952H9.31744V3.98596H0.932861V12.9952Z" fill="#2747AE" />
        <path
            d="M3.20638 1.26353C3.75959 1.26353 4.21606 1.58099 4.82845 2.00246C5.66437 2.6202 6.32465 2.91957 7.11174 2.91957C8.58822 2.91957 9.30767 1.93644 9.30767 0.421488H8.02467C7.97677 1.23549 7.60671 1.65606 7.00466 1.65606C6.40261 1.65606 5.84846 1.16947 5.17878 0.710911C4.57579 0.299388 3.93523 1.52588e-05 3.18666 1.52588e-05C1.83603 1.52588e-05 0.923096 0.92617 0.923096 2.4981H2.21549C2.25494 1.6651 2.66163 1.26353 3.20638 1.26353Z"
            fill="#2747AE" />
        <path
            d="M61.3433 3.98596H54.377L54.115 8.3653C53.9985 10.2646 53.5317 11.3961 53.1241 11.6955H52.1914V15.4091H53.6313V13.0232H61.3837V15.4082H62.8311V11.6946H61.3433V3.98596ZM59.8406 11.6946H54.8147C55.1444 11.236 55.4656 9.96346 55.5661 8.13919L55.7211 5.3146H59.8406V11.6946Z"
            fill="#2747AE" />
        <path
            d="M87.243 3.87383C84.9794 3.87383 83.4926 4.92118 83.2691 6.68937H84.7719C84.9597 5.76322 85.8407 5.16447 87.1622 5.16447C88.6293 5.16447 89.4934 5.87537 89.4934 7.03578V7.66256H86.7443C84.2769 7.66256 82.9751 8.66378 82.9751 10.4663C82.9751 11.8601 84.0627 13.0956 86.1131 13.0956C87.7164 13.0956 88.9599 12.4218 89.5526 11.6168L89.7658 12.9925H90.9999V7.12893C91.0028 5.02428 89.623 3.87383 87.243 3.87383ZM89.4972 9.07531C89.4972 10.6002 88.264 11.8628 86.3301 11.8628C85.0865 11.8628 84.5042 11.2641 84.5042 10.375C84.5042 9.35568 85.2621 8.87814 86.593 8.87814H89.4972V9.07531Z"
            fill="#2747AE" />
        <path
            d="M82.3848 3.98596H80.636L77.7215 7.74666H75.8374V3.98596H74.3318V12.9952H75.8374V9.07529H77.7412L80.8595 12.9952H82.676L78.9744 8.30832L82.3848 3.98596Z"
            fill="#2747AE" />
        <path
            d="M65.5779 10.8055V3.98596H64.1108V12.9952H65.4323L70.863 6.15573V12.9952H72.3395V3.98596H71.018L65.5779 10.8055Z"
            fill="#2747AE" />
        <path
            d="M47.13 3.87383C44.1375 3.87383 42.3398 5.88441 42.3398 8.48651C42.3398 11.0886 44.1366 13.0992 47.13 13.0992C50.1233 13.0992 51.9295 11.0967 51.9295 8.48651C51.9295 5.87627 50.1318 3.87383 47.13 3.87383ZM47.13 11.7787C45.1284 11.7787 43.8652 10.4971 43.8652 8.48561C43.8652 6.47411 45.1284 5.19975 47.13 5.19975C49.1315 5.19975 50.4041 6.48135 50.4041 8.4838C50.4041 10.4862 49.1409 11.7787 47.13 11.7787Z"
            fill="#2747AE" />
        <path
            d="M34.0331 10.8055V3.98596H32.5557V12.9952H33.8772L39.3182 6.15573V12.9952H40.7853V3.98596H39.4638L34.0331 10.8055Z"
            fill="#2747AE" />
    </g>

    <text fill="#2747AE" xml:space="preserve" style="white-space: pre" font-family="Aeonik Pro" font-size="12"
        letter-spacing="0em" x="278" y="468">periodica.press
    </text>
</svg>`;
