import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';

import { Button, LoaderButton } from '@periodica/ui-kit';
import { Header } from '@components/Blocks/Header';
import MiniProductBlock from '@components/Blocks/MiniProductBlock';
import InfoStringBlockfrom from '@components/Blocks/InfoStringBlock';
import DeliveryInformation from '@components/Blocks/DeliveryInformation';
import Certificate from '@components/Blocks/CertificatePreview';

import discountPic from '@images/icons/discount.svg';
import receiptPic from '@images/icons/receipt.svg';
import tabsPic from '@images/icons/tabs.svg';
import supportPic from '@images/icons/icon-support.svg';

import { logGiftPurchase } from '@analytics/gtm';
import { orderGift } from '../../../periodicaApi/gift';

// TODO: move styles
import styles from './GiftConfirmation.module.scss';

function GiftConfirmationPage() {
  const location = useLocation();
  const { state } = location;
  const {
    emailTo,
    nameFrom,
    email,
    nameTo,
    pattern,
    phone,
    sum,
    customSum,
    previewUrl,
    isDesignOn,
    // eslint-disable-next-line camelcase
    client_newsletter_agreed,
  } = state;
  const tempSum = parseInt(sum?.sum || customSum, 10);
  const totalSum = isDesignOn ? tempSum + DEFAULT_DESIGN_PRICE : tempSum;

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!state) {
    navigate('/products/gift');
    return null;
  }

  const returnToGiftScreen = () => {
    navigate('/products/gift/', {
      state: { ...state },
    });
  };

  const isDeliveryToPerson = state?.deliveryType === 1;

  const getHeader = isDeliveryToPerson
    ? 'Это увидит получатель на почте'
    : 'Так будет выглядеть PDF файл';

  const getMiniProductBlock = (p, idx) => {
    if (idx > 1) return null;
    return <MiniProductBlock photo={p.image} title={p.title} subtitle={p.description} key={p.id} />;
  };

  const handlePay = async () => {
    try {
      setIsSubmitting(true);
      const order = await orderGift(
        pattern.template_id,
        nameFrom,
        phone,
        email,
        nameTo,
        isDeliveryToPerson ? emailTo : null,
        pattern.text,
        totalSum,
        previewUrl,
        client_newsletter_agreed
      );
      await logGiftPurchase(order);
      if (order.payment_url) {
        navigate(order.payment_url);
      } else {
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.h1}>
        <Header>Проверьте ваш заказ</Header>
      </div>
      <div className={styles.s1}>{getHeader}</div>
      <div className={styles.content}>
        {isDeliveryToPerson ? (
          <div className={styles.giftInfo}>
            <div className={styles.h1}>{nameTo}</div>
            <div className={styles.p2}>
              <p>Вам сертификат в подарок!</p>
            </div>
            <div className={styles.giftCard}>
              <Certificate
                image={pattern.frontLayout}
                text={pattern.text}
                nameTo={nameTo}
                nameFrom={nameFrom}
                sum={totalSum}
              />
            </div>
          </div>
        ) : (
          <div className={styles.giftCard}>
            <Certificate
              image={pattern.frontLayout}
              text={pattern.text}
              nameTo={nameTo}
              nameFrom={nameFrom}
              sum={totalSum}
            />
          </div>
        )}
        {!!sum && (
          <div className={styles.otherProducts}>
            <h3 className={styles.h3}>На сумму сертификата можно заказать</h3>
            {sum.products.map(getMiniProductBlock)}
          </div>
        )}
        <div className={styles.otherProducts}>
          <h3 className={styles.h3}>С сертификатом можно</h3>
          <InfoStringBlockfrom image={supportPic} description="Заказать любые продукты Периодики" />
          <InfoStringBlockfrom
            image={discountPic}
            description="Оплатить заказ целиком или его часть"
          />
          <InfoStringBlockfrom
            image={receiptPic}
            description="Использовать несколько раз до исчерпания баланса"
          />
          {isDesignOn && (
            <InfoStringBlockfrom
              image={tabsPic}
              description="Оплатить услугу «Соберем фотокнигу за вас»"
            />
          )}
        </div>
        <div className={styles.deliveryWrapper}>
          <DeliveryInformation
            mail={isDeliveryToPerson ? emailTo : email}
            deliveryToPerson={isDeliveryToPerson}
            phone={phone}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="button" onClick={returnToGiftScreen} variant="brandSecondary" size="xLarge">
            Изменить дизайн и данные
          </Button>
        </div>
        <LoaderButton
          type="button"
          size="xLarge"
          onClick={handlePay}
          className={styles.submitButton}
          loading={isSubmitting}
        >
          К оплате
        </LoaderButton>
      </div>
    </div>
  );
}

export default GiftConfirmationPage;
