import React from 'react';
import styles from './InfoStringBlock.module.scss';

interface InfoStringBlockArgs {
  image: string;
  description: string;
}

function InfoStringBlock({ image, description }: InfoStringBlockArgs) {
  return (
    <div className={styles.infoBlock}>
      <img src={image} alt="изображение" />
      <p className={styles.s2}>{description}</p>
    </div>
  );
}

export default InfoStringBlock;
